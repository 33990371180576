export enum Urls {
  Codewars = "https://www.codewars.com/users/sprakash57",
  Facebook = "https://www.facebook.com/sprgm",
  Github = "https://github.com/sprakash57",
  Linkedin = "https://www.linkedin.com/in/sunnyprakash12",
  Mastodon = "https://mastodon.social/@sunnyprakash",
  Medium = "https://sunny-prakash.medium.com",
  Stackoverflow = "https://stackoverflow.com/users/9585068/sunny-prakash",
  SubscribeMedium = "https://medium.com/m/signin?actionUrl=%2F_%2Fapi%2Fsubscriptions%2Fnewsletters%2F1bb76288a4cc&operation=register&redirect=https%3A%2F%2Fsunny-prakash.medium.com%2F&newsletterV3=9ca15ea682bd&newsletterV3Id=1bb76288a4cc&user=Sunny+Prakash&userId=9ca15ea682bd&source=---two_column_layout_sidebar-----------------------subscribe_user-----------",
  SubscribeYoutube = "https://www.youtube.com/c/ExpatTravelFinds?sub_confirmation=1",
  Twitter = "https://twitter.com/sunny_pr_",
  Website = "https://expattravelfinds.com/contact/",
  Youtube = "https://www.youtube.com/c/ExpatTravelFinds",
  Playstore = "https://play.google.com/store/apps/dev?id=6669510591494027903",
  "Expat Travel Finds" = "https://expattravelfinds.com/",
  Resume = "https://drive.google.com/file/d/1R6Dj6pQKRez7eF9TMf99l6rtHmXmOV0_/view?usp=sharing",
}

export enum Sections {
  Introduction = "Introduction",
  Read = "Read",
  Explore = "Explore",
  Watch = "Watch",
  Contact = "Contact",
}

export const Icons = {
  Linkedin: "Linkedin",
  Medium: "Medium",
  Twitter: "Twitter",
  Github: "Github",
  Arrow: "Arrow",
  Stackoverflow: "Stackoverflow",
  Playstore: "Playstore",
  Codewars: "Codewars",
  Facebook: "Facebook",
  "Expat Travel Finds": "Expat Travel Finds",
  ClipboardChecked: "ClipboardChecked",
  Clipboard: "Clipboard",
  Download: "Download",
  Copyright: "Copyright",
};
